import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDDCyRHISwds8fFLLgl412koiUmFVd7AjM",
  authDomain: "ai-alchemist.firebaseapp.com",
  databaseURL: "https://ai-alchemist-default-rtdb.firebaseio.com",
  projectId: "ai-alchemist",
  storageBucket: "ai-alchemist.appspot.com",
  messagingSenderId: "738765834796",
  appId: "1:738765834796:web:94a196237f9af990371f89",
  measurementId: "G-W9Q6GJ2XDM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
