import React, { useEffect, useState } from 'react';
import { FaGooglePlay } from 'react-icons/fa';
import { heroData } from '../data';
import Header from './Header';

const Hero = ({ mediaURL, setShowLogin, privacyTitle }) => {
  const { title, subtitle, btnText, image, video } = heroData;
  const isVideo = mediaURL.endsWith('.mp4');

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Effect to update state on window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <section className='lg:h-[900px] py-12' style={{ position: 'relative' }}>
      <Header setShowLogin={setShowLogin}/>
      <div className='container mx-auto h-full relative'>
        <div className='flex flex-col xl:flex-row items-center h-full md:py-24'>
        {/* <div
            className='absolute -right-16 -bottom-36 xl:hidden'
            // data-aos='fade-up'
            // data-aos-delay='700'
          >
            <img src={mediaURL} alt='' />
          </div> */}
          <div className='text-center xl:text-left xl:absolute'>
            <h1
              className='h1 xl:max-w-[700px] mb-6 xl:mb-12 text-white'
              data-aos='fade-down'
              data-aos-delay='400'
            >
              {title}
            </h1>
            <p
              className='p lead text-2xl xl:max-w-[380px] mb-6 lg:mb-12'
              data-aos='fade-down'
              data-aos-delay='500'
            >
              {subtitle}
            </p>
            {/* <button
              className='btn btn-primary mb-8 xl:mb-0'
              data-aos='fade-down'
              data-aos-delay='600'
              style={{ display: 'flex', alignItems: 'center' }}
            >
            <FaGooglePlay className='text-2xl text-accent-primary group-hover:text-white transition' />
              {btnText}
            </button> */}
          </div>
          
        </div>
      </div>
      

      {isVideo ? (
        <video
        src={video}
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '180%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />
      ) : (
        // <img src={mediaURL} alt="Media" style={{
        //   position: 'absolute',
        //   top: 0,
        //   left: 0,
        //   right:0,
        //   width: '100%',
        //   height:'auto',
        //   objectFit: 'cover',
        //   zIndex: -1,
        // }} />
        <img src={mediaURL} alt="Media" className="absolute top-0  w-auto h-screen object-cover z-[-1] md:w-screen md:bottom-auto md:top-0 xl:h-auto xl:w-screen" />
      )}
    </section>
  );
};

export default Hero;
