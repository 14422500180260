import React, { useState } from 'react';
// import { GoogleLogin } from '@leecheuk/react-google-login';
import { GoogleLogin } from '@react-oauth/google';
import { getAuth, signInWithCredential, GoogleAuthProvider, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';



const Login = ({ onClose, setShowLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    const auth = getAuth();

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('User logged in:', userCredential.user);
      navigate('/account-management');
      setShowLogin(false)
      // Handle successful login
    } catch (error) {
      console.error('Error logging in with email and password:', error);
      // Handle errors
    }
  };

  const handleGoogleSuccess = (credentialResponse) => {
    const auth = getAuth();
    const credential = GoogleAuthProvider.credential(credentialResponse.credential);
    
    signInWithCredential(auth, credential)
      .then((userCredential) => {
        // User successfully logged in with Google
        console.log('Firebase Auth User:', userCredential.user);
        navigate('/account-management');
        setShowLogin(false)
      })
      .catch((error) => {
        console.error('Firebase Auth Error:', error);
      });
    };

    const handleGoogleFailure = (error) => {
      console.error('Google Login Failed:', error);
    };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-backgroundColor p-8 rounded relative">
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-lg text-white"
        >
          &#10005; {/* This is the Unicode for the X symbol */}
        </button>
        <form onSubmit={handleLogin} className="space-y-4">
          <h2 className="text-center text-xl font-bold">Login</h2>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-white">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-white">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              required
              className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Login
            </button>
          </div>
        </form>

        {/* Separator */}
        <hr className="my-4 border-gray-300" />
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
        />
      </div>
    </div>
  );
};

export default Login;