import React from 'react';
import { ctaData } from '../data';
import { FaGooglePlay, FaApple } from 'react-icons/fa';  // Importing Apple icon

const CtaSection = () => {
  const { title, subtitle, btnText1, btnText2 } = ctaData;

  const googlePlayLink = "https://play.google.com/store/apps/details?id=com.ai.alchemist";  // Add your Google Play link here
  const appStoreLink = "https://apps.apple.com/us/app/ai-alchemist/id6467228918";    // Add your App Store link here

  return (
    <section className='my-[75px] xl:my-[150px] border-t-2' data-aos='fade-up'>
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row justify-between items-center'>
          <div className='py-12 xl:py-24 text-center xl:text-left'>
            <h2 className='h2 mb-5' data-aos='fade-up' data-aos-delay='300'>
              {title}
            </h2>
            <p className='lead' data-aos='fade-up'>
              {subtitle}
            </p>
          </div>
          <div className='flex flex-col xl:flex-row gap-y-4 gap-x-[30px]'>
            <a href={googlePlayLink} className='btn btn-quaternary flex items-center gap-x-[20px] group' data-aos='fade-up' data-aos-delay='400'>
              <FaGooglePlay className='text-2xl text-accent-primary group-hover:text-white transition' />
              {btnText2}
            </a>
            <a href={appStoreLink} className='btn btn-quaternary flex items-center gap-x-[20px] group' data-aos='fade-up' data-aos-delay='500'>
              <FaApple className='text-2xl text-accent-primary group-hover:text-white transition' />
              {btnText2}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
