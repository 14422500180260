import React, { useState } from 'react';

// import components
import Icon from '../assets/img/Icon.png';
import GoogleIcon from '../assets/img/google-play-badge-200h.png';
import iOSIcon from '../assets/img/app-store-badge-200h.png';

import Nav from './Nav';
import NavMobile from './NavMobile';

// import icons
import { FaBars } from 'react-icons/fa';
import { FaGooglePlay } from 'react-icons/fa';

const Header = ({ setShowLogin }) => {
  const [navMobile, setNavMobile] = useState(false);
  return (
    <header
      className='mb-12 lg:mb-0 z-20 relative px-4 lg:px-0'
      data-aos='fade-down'
      data-aos-delay='1200'
      data-aos-duration='1000'
    >
      <div className='container mx-auto'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-x-[120px]'>
            {/* logo */}
            <a href='/'>
              <img src={Icon} alt='' />
            </a>

            {/* nav / initially hidden / show on large screens  */}
            <div className='hidden lg:flex'>
              <Nav setShowLogin={setShowLogin} />
            </div>
          </div>

          {/* mobile nav / initially is showing / hide on large screens */}
          <div
            className={`${
              navMobile ? 'max-h-600' : 'max-h-0'
            } lg:hidden absolute top-24 bg-accent-tertiary w-full left-0 right-0 font-bold rounded transition-all overflow-hidden`}
          >
            <NavMobile setShowLogin={setShowLogin} setNavMobile={setNavMobile}/>
            
          </div>

          {/* <button className='btn btn-quaternary flex items-center gap-x-[20px] group'>
            Download now
            <FaGooglePlay style={{ marginRight: '8px' }} />
          </button> */}
          <div className={`flex space-x-4 hidden lg:flex`}>
            <a href="https://apps.apple.com/us/app/ai-alchemist/id6467228918" target="_blank" rel="noopener noreferrer">
              <img src={iOSIcon} alt="Download on the App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.ai.alchemist" target="_blank" rel="noopener noreferrer">
              <img src={GoogleIcon} alt="Get it on Google Play" />
            </a>
          </div>



          {/* nav trigger btn / only shows on mobile screens */}
          <div
            onClick={() => setNavMobile(!navMobile)}
            className='text-2xl text-primary cursor-pointer lg:hidden'
            style={{ position: 'relative', top: '-10px' }}
          >
            <FaBars />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
