import React from 'react';

// import navigation data
import { navigationData } from '../data';

const Nav = ({ setShowLogin }) => {

  const handleAccountClick = () => {
    setShowLogin(true);
  };


  return (
    <>
    <nav>
      <ul className='flex gap-x-8'>
        {navigationData.map((item, index) => {
          return (
            <li key={index}>
              <a href={item.href} className="text-white hover:text-primary" onClick={item.name === 'Account' ? handleAccountClick : null}>{item.name}</a>
            </li>
          );
        })}
      </ul>
    </nav>
    </>
  );
};

export default Nav;
