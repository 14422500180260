import './TermsService.css'; // You'll have to create this stylesheet
import styles from './TermsService.module.css';
import React, { useEffect, useState } from 'react';


const TOS = () => {
  return (
    <div className={styles.container}> {/* Use styles.container */}
      <h1 className="title">AI Alchemist: Terms of Service</h1>
      <div className="contentContainer">
        <p className="dateOfRevision">
          Date of Revision: [ 07/2023 ]
        </p>
        <p className="content">
        AI Alchemist (the “APP”). The Terms of Service (the “TOS”) set forth here govern the relationship between you and us, and serve as an agreement that governs your access and use of the APP and our related services. For the purposes of the TOS, “you” and “your” refers to you, the user of the APP.
        </p>
        <p className="content">
        AI Alchemist is an innovative app that uses advanced artificial intelligence algorithms to transform text into unique content (“Services”). Some parts of the Services provided by the APP may require you to create a user account.
        </p>
        <p className="content">
        You start by typing text that describes the content you want to generate. After defining some features (style, size, proportion, quantity, etc.), our artificial intelligence algorithms generate content based on your settings.
        </p>
        <p className="content">
        By downloading, updating, accessing and/or using the APP, you agree to abide by this TOS. You acknowledge and agree that we will treat your access or use of the APP as acceptance of the TOS and our Privacy Policy. If you do not agree with this TOS, please refrain from using our APP, and you will consequently not be able to access any part of our Services.
        </p>
        <p className="content">
        You agree that by downloading the APP from the Apple Store, Google Play Store (indicated or recognized by us), you are bound by the respective terms and conditions of such distribution platforms.
        </p>
        <p className="heading">1. Account</p>
        <p className="content">
            1.1 Parts of the Services offered by the APP may require you to create a user account and/or user email and/or user ID and/or password (the “Protected Areas”). In the event of accessing Protected Areas, you agree to access only using your registered user account and/or ID and password.
        </p>
        <p className="content">
            You could:
        </p>
        <p className="content">
            • Sign in with email
            • Log in with Apple third-party
            • Log in with Google third-party
        </p>
        <p className="content">
            You hereby represent and warrant that all information you submit to create a user account is true and correct, and you have the full rights to submit such information.
        </p>
        <p className="content">
            You agree to, from time to time as necessary, update any information associated with your user account or user ID (including but not limited to, your email, payment information (subscriptions and email address, or other supplemental information as the case may be) so that it remains current, accurate and correct at all times. You agree to protect the confidentiality of your user ID and password, and not to share or disclose your user ID or password to any third party. You agree that you are fully responsible for all activities occurring under your user account and/or user ID. Your user account and user ID are non-transferrable. You cannot sell, lend, or otherwise share it with any other person.
        </p>
        <p className="content">
            Any violation of these Terms of Service, including but without limitation, failure to maintain updated and correct information about your user account may cause your user account to fall out of good standing and we may cancel your user account at our sole discretion.
        </p>
        <p className="content">
            Meanwhile, we reserve the right to terminate or restrict your user account and/or user ID, or otherwise revoke your access to the APP or Services provided by us, at any time for reasons or suspicions which indicate you have violated the terms of TOS or Privacy Policy, at our sole discretion. You agree that we will not be liable to you or any third party for, without limitation, any denial of use of the APP or the content or Services offered by us, any change of costs for third party Services or fees or otherwise or from suspension or termination of your user account and/or user ID.
        </p>
        <p className="content">
            1.2 Subscription
        </p>
        <p className="content">
            We retain the right, at our sole discretion to provide some Services, which will be available only for paid subscribers. The subscription will begin after the initial payment and the payment should be performed pursuant to the fee terms as presented in the Site at the time of the purchase. You are responsible for payment of all fees, charges and taxes (if required by law) related to the transaction.
        </p>
        <p className="content">
            Please note that if you download the APP or subscribe the Services from a distribution platform which is not indicated or recognized by us, we are not responsible for any costs or liability arising from your action.
        </p>
        <p className="heading">2. Legal Capacity</p>
        <p className="content">
            You understand and warrant that:
        </p>
        <li className="bulletItem">
            If you are entering into this TOS on behalf of another person, You are fully and duly authorized by such person to enter into this TOS which will be binding upon both You individually and such other person (and “You” as used in this TOS shall refer to both);
        </li>
        <li className="bulletItem">
            You are of the legal age in the jurisdiction of your place of domicile to form a binding contract with us;
        </li>
        <li className="bulletItem">
            if you are under the legal age in the jurisdiction of your place of domicile, you shall access or use our Services under the supervision of your parent(s) or legal guardian who agrees to be bound by these Terms and subjected to the local laws and regulations;
        </li>
        <li className="bulletItem">
            You are not a person barred from subscribing to, using or accessing the APP under the laws of the Hong Kong Special Administrative Region (HKSAR) or other applicable jurisdictions, as the case may be.
        </li>
        <p className="heading">3. Intellectual Property</p>
        <p className="content">
            The intellectual property in the APP and embedded materials (including without limitation technology, systems, files, documents, text, photographs, information, images, videos, audios, and software, individually or in combination) in Ai Alchemist are owned by or licensed to us. You may download Ai Alchemist, to view, use, and display the application on your mobile device for your personal use only.
        </p>
        <p className="content">
            This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you violate any of these restrictions or these Terms of Service, and may be terminated by us at any time.
        </p>

        <p className="heading">4. Types of Content Uploads</p>

        <p className="heading">4.1 Content Submitted to Public Gallery</p>
        <p className="content">
            When you submit content to be shared publicly in Ai Alchemist's gallery feature, you retain your intellectual property ownership rights. However, by submitting your content to the public gallery, you grant us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to use, distribute, modify, run, copy, publicly display, translate, or create derivative works of your content consistent with our Privacy Policy.
        </p>
        <p className="content">
            This license is necessary for us to operate the public gallery feature and promote your content within the app. The license can be terminated by you at any time by deleting your content from the Gallery feature or account. Note that the license may continue for already initiated commercial or sponsored content until it is discontinued.
        </p>
        <p className="content">
            You also give us permission to use your username and other identifying information associated with your account in ways that are consistent with your privacy preferences and our Privacy Policy.
        </p>

        <p className="heading">4.2 Private Content Uploads for Image Generation</p>
        <p className="content">
            For content uploaded for the purpose of generating new images, which is not submitted to the public gallery, no license to the content is granted to us. We do not claim any ownership rights over such content. This type of content is used solely to facilitate the generation of your requested image and is not monitored, shared, or retained by us.
        </p>
        <p className="content">
            Uploaded content for image generation is stored temporarily and is automatically deleted within 24 hours. We ensure that this data is handled securely and in accordance with our Privacy Policy, with no permanent copies made and no viewing or usage beyond the specified service.
        </p>
        <p className="content">
            We are committed to maintaining the privacy and security of your private uploads and employ all reasonable measures to protect your information while it is in our possession.
        </p>

        <p className="heading">Community Guidelines for the Public Gallery</p>
        <p className="content">
            As part of our commitment to fostering a creative and respectful community environment, we require all users who participate in Ai Alchemist's gallery to adhere to the following community guidelines. Compliance with these guidelines ensures a safe and positive experience for all users. By submitting content to the public gallery, you agree to comply with these guidelines.
        </p>

        <p className="heading">Acceptable Content:</p>
        <p className="content">
            1. Original Creations: Users are encouraged to share original artwork, designs, and creative projects created using the Ai Alchemist app, ensuring that all submissions are the original work of the submitting user.
        </p>
        <p className="content">
            2. Respectful Content: Content must uphold a standard of respect and kindness toward all individuals and groups, avoiding any expressions of discrimination, hate, or disrespect based on race, ethnicity, gender, religion, sexual orientation, disability, or any other characteristic.
        </p>
        <p className="content">
            3. Appropriate Content: All shared content must be appropriate for a diverse audience, free from explicit or mature themes such as nudity, violence, hate speech, and illegal activities.
        </p>
        <p className="content">
            4. Accurate Attribution: Where content incorporates elements created by others, the user must possess the requisite permissions and shall provide due attribution to the original creators.
        </p>

        <p className="heading">Prohibited Content:</p>
        <p className="content">
            1. Intellectual Property Violations: Content must not infringe on the intellectual property rights of others, including but not limited to copyrighted images, logos, trademarks, or designs.
        </p>
        <p className="content">
            2. Misleading Content: Content that misinforms or deceives users is strictly prohibited.
        </p>
        <p className="content">
            3. Spam and Promotional Content: The gallery shall not be used for the dissemination of spam or promotional material unrelated to the Ai Alchemist community.
        </p>
        <p className="content">
            4. Harmful Content: Content promoting self-harm, violence, illegal activities, or harmful behaviors is forbidden.
        </p>
        <p className="content">
            5. Bullying and Harassment: Bullying, harassment, or any form of targeted attack against other users is unacceptable. Respect for privacy and personal boundaries is mandatory.
        </p>

        <p className="heading">Enforcement and Compliance:</p>
        <p className="content">
            Ai Alchemist reserves the right to review, moderate, and remove any content submitted to the public gallery that is found to be in violation of these guidelines. Our moderation team will take appropriate actions, which may include removing content, restricting access, and suspending or banning users who fail to adhere to these guidelines.
        </p>
        <p className="content">
            Users are encouraged to report any violations of these guidelines to our moderation team for investigation. We rely on our community to help uphold the standards set forth herein and to maintain a respectful and inspiring environment.
        </p>

        <p className="heading">Contact for Concerns:</p>
        <p className="content">
            Should you have any questions or concerns regarding these community guidelines or if you encounter issues with content in the gallery, please reach out to our support team via <a href="mailto:support@aialchemist.com">support@aialchemist.com</a>.
        </p>

        <p className="heading">5. Restrictions of Conduct and Content</p>
        <p className="content">5.1 In accessing and using the APP, you agree to abide by the following rules, restrictions and limitations:</p>
        <li className="bulletItem">You will not modify, translate, adapt or reformat the APP;
        </li>
        <li className="bulletItem">You will not decipher, decompile, disassemble, or reverse-engineer, or otherwise attempt to discover the source code or structure of, the software or materials comprising the APP (except where the foregoing is permitted by applicable local law notwithstanding such restrictions, and then only to the extent that such intended activities are disclosed in advance in writing to us);
        </li>
        <li className="bulletItem">You will not interfere with or circumvent any security feature of the APP or any feature that restricts or enforces limitations on the use of the APP;
        </li>
        <li className="bulletItem">You will not use the APP to gain unauthorized access to our or any third party's data, systems or networks;
        </li>
        <li className="bulletItem">You will not use the APP in any manner that could damage, disable, overburden, impair or otherwise interfere with or disrupt our systems and networks, or otherwise interfere with other users' use of the APP;
        </li>
        <li className="bulletItem">You will not use the APP in any way that, in our sole discretion, may expose us and others to liability or damages;
        </li>
        <li className="bulletItem">You will not remove, change or obscure any copyright, trademark notice, trademark, hyperlink or other proprietary rights notices contained in the APP; and
        </li>
        <li className="bulletItem">You will comply with all applicable laws in your access and use of the APP, including the laws of your country or district if you live outside of the Hong Kong Special Administrative Region (HKSAR).
        </li>
        <br/>
        <p className="content">5.2 You shall not upload, share or otherwise transmit to or via the Services any content (including photos, text, video, etc.) that:
        </p>
        <li className="bulletItem">is unlawful, harmful, libelous, defamatory, obscene, abusive, racially or ethnically offensive, pornographic, indecent, lewd, harassing, threatening, invasive of personal privacy or publicity rights, misleading, incendiary, or otherwise objectionable;
        </li>
        <li className="bulletItem">would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party or otherwise create liability or violate any local, state, national or international law;
        </li>
        <li className="bulletItem">may infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary right of any third party;
        </li>
        <li className="bulletItem">contains any unsolicited promotions, political campaigning, advertising or solicitations;
        </li>
        <li className="bulletItem">contains any private or personal information of a third party without such third party's consent;
        </li>
        <li className="bulletItem">may harm or exploit children by exposing them to inappropriate content, asking for personally identifiable details or otherwise;
        </li>
        <li className="bulletItem">contains any viruses, corrupted data or other harmful, disruptive or destructive files or content, designed to interrupt, destroy or limit the functionality of the Application;
        </li>
        <li className="bulletItem">contains any information or content that you do not have a right to make available under any law or under contractual or fiduciary relationships (e.g., inside information, confidential information received in the context of an employment or a non-disclosure agreement); or
        </li>
        <li className="bulletItem">is in our sole judgment, objectionable or that restricts or inhibits any other person from using or enjoying our Services, or that may expose us or others to any harm or liab
        illity of any tlipe.
        </li>
        <p className="content">
            We reserves the right, but is not obligated, to reject and/or remove any user content that we believe, in our sole discretion, violates these provisions. If you have noticed any violation of these Terms from your prospective, content of any nature whatsoever, please contact us at: support@ai-alchemist.live or directly report in the APP.
        </p>
        <p className="content">5.3 Unless otherwise agreed, any pictures, texts and other contents that you watch, share, save, use and forward in this APP shall not be used for commercial purposes.</p>
        <p className="heading">6. Claims of Infringement</p>

        <p className="content">6.1 Copyright</p>

        <p className="content">If you believe in good faith that materials transmitted or created through the APP infringe your copyright, you (or your agent) may send us a notice requesting that we remove the material or block access to it. Please provide the following information in writing:
        </p>

        <li className="bulletItem">an electronic or physical signature of the owner (or person authorized to act on behalf of the owner) of the copyrighted work;
        </li>
        <li className="bulletItem">a description of the copyrighted work that you claim has been infringed upon and sufficient information for us to locate such copyrighted work;
        </li>
        <li className="bulletItem">Your address, telephone number, and e-mail address;
        </li>
        <li className="bulletItem">a statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;
        </li>
        <li className="bulletItem">a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
        </li>

        <p className="content">We will 1) take proper preliminary actions against said alleged infringement within 1-3 days after receipt of said information, including without limitation link blockage; 2) notify the alleged infringer and demand him or her to explain and provide counter evidence.</p>

        <p className="content">If you believe in good faith that someone has wrongly filed a notice of copyright infringement against you, you may send us a counter-notice. If you do, we will notify the alleged claimant and hold process for 10-14 days and then re-enable your content unless the copyright owner initiates a legal action against you before then. Our counter-notice form, set forth below, may be referenced to the forms recommended by the DMCA statute, which can be found at the U.S. Copyright Office's official website: http://www.copyright.gov. If counter-notice is not filed within 7 days, we will review and process the notice in accordance with the contents of the notice. Notices and counter-notices should be sent to us via email at:support@ai-alchemist.live.</p>

        <p className="content">6.2 Other rights</p>

        <p className="content">If you believe in good faith that materials in or to the APP infringe your other rights, you (or your agent) may send us a notice requesting that we remove the material or block access to it. Please provide relevant information that could prove basic facts of the infringement in writing when you contact us.</p>

        <p className="content">6.3 We have the right to suspend or terminate the use of the APP by anyone engaged in suspected repeated any infringement involved above.</p>
        <p className="heading">7. Liability</p>

        <p className="content">THE APP IS PROVIDED TO YOU ON AN "AS-IS" AND "AS AVAILABLE" BASIS AND THE USE THEREOF IS AT YOUR SOLE RISK. WE MAKE NO, AND HEREBY DISCLAIM, ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, NONINFRINGEMENT, AND TITLE WITH RESPECT TO THE APP, TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW.</p>

        <p className="content">WE DO NOT WARRANT THAT:</p>
        <li className="bulletItem">THE APP (OR THE RESULTS OBTAINED FROM THE USE THEREOF) WILL BE TIMELY, ERROR-FREE, SECURE OR UNINTERRUPTED;</li>
        <li className="bulletItem">THE APP MEET YOUR REQUIREMENTS; OR</li>
        <li className="bulletItem">THE ACCURACY, LIKELY RESULTS, OR RELIABILITY OF THE USE OF THE MATERIALS IN AI ALCHEMIST OR ON OUR WEBSITE, OR OTHERWISE RELATING TO SUCH MATERIALS OR ON ANY RESOURCES LINKED TO AI ALCHEMIST AND OUR WEBSITE.</li>
        <li className="bulletItem">ANY ERRORS OR MALFUNCTIONS IN THE APP WILL BE CORRECTED.</li>

        <p className="content">WE SHALL IN NO EVENT BE RESPONSIBLE OR LIABLE TO YOU OR TO ANY THIRD PARTY, WHETHER UNDER CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, INDEMNITY OR OTHER THEORY, FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, LIQUIDATED OR PUNITIVE DAMAGES OR ANY OTHER DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFIT, REVENUE OR BUSINESS, COST OF SUBSTITUTE PROCUREMENT, ARISING IN WHOLE OR IN PART FROM YOUR USE OF (OR INABILITY TO USE) THE APP, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNDER NO CIRCUMSTANCES SHALL WE BE HELD LIABLE FOR ANY DELAY OR FAILURE IN PERFORMANCE RESULTING DIRECTLY OR INDIRECTLY FROM ANY CAUSES BEYOND ITS REASONABLE CONTROL.</p>

        <p className="heading">8. Links to Third Party Websites and Services</p>

        <p className="content">The APP may include links or allow access to third-party websites and services. Please note, their presence does NOT mean that they are recommended by us and we do not guarantee their safety and conformity with any your expectations. We assume no obligations in the event of any damage or loss, or any other impact, directly or indirectly resulting from the use of any content, goods or services available on or through any such third-party websites and services.</p>

        <p className="content">It is your responsibility to take precautions to ensure that anything you select for your use or download, whether from the APP or a Third Party APP, is free of such items as viruses, worms, Trojan horses, and other items of a destructive nature. We assume no responsibility, and is not liable, for any transmission or material, or any viral infection of your computer equipment or software, or any other types of damage related to your access, use of browsing of Third Party APPs or Third Party Content. If you decide to access a Third Party APP, you do this entirely at your own risk and you should review the terms of use and privacy policy or similar terms governing the use of such Third Party APPs.</p>
        <p className="heading">9. Governing Law; Dispute Resolution</p>

        <p className="content">This TOS shall be governed by and construed in accordance with the internal laws of Hong Kong Special Administrative Region (HKSAR) without giving effect to its conflict of law’s provisions, regardless of your location.</p>

        <p className="content">Any dispute arising out of or in connection with the TOS, including any question regarding existence, validity or termination of the TOS, shall be referred to and finally resolved by the courts of HKSAR.</p>

        <p className="heading">10. Changes to this TOS</p>

        <p className="content">We reserve the right to update or make changes to this TOS from time to time in our sole discretion, and we may notify you of changes by making the revised version of this TOS accessible through the APP, which changes will become effective immediately. Please return to this TOS periodically to ensure familiarity with the latest version of this TOS, so that you can determine when this TOS was last revised by referring to the “Date of Revision” at the top of this TOS. If you do not agree with the revised TOS, you have the right and should immediately stop using the APP, your continued access or use of the APP after any changes to this TOS have been posted means your agreement and consent to such changes.</p>

        <p className="content">We reserve the right to change the Services scope listed herein and change charging standard applicable to the Services at any time for any reason in our sole discretion and without notice. We are entitled to stop or restrict provision of the Services in full or in part toward a certain user. We retain powers to discontinue provision and/or support of the Services without any prior notice.</p>
        <p className="heading">11. Termination; Assignment</p>

        <p className="content">This TOS will continue in effect until terminated by either you or us as set out below. You may terminate this TOS at any time by ceasing your access and use of the APP. In the case that you are a subscriber to us, the subsequent processing of fees shall be subject to the respective rules of the Apple Store and Google Play (indicated or recognized by us). We may terminate this TOS and your right to access or use the APP, with or without notice to you, for any reason, including suspected breach of this TOS by you.</p>

        <p className="content">We reserve and retain the rights to assign, transfer or subcontract the Services to any third parties. Notice will be posted on the APP and your continuing use or update of the APP means your consent to such assignment.</p>

        <p className="heading">12. Miscellaneous</p>

        <p className="content">If any provision of this TOS is found to be unlawful, void or for any reason unenforceable, that provision will not affect the validity and enforceability of any remaining provision and such provision will be enforced to the maximum extent possible so as to effect the intent of the parties.</p>

        <p className="content">This TOS, together with the Privacy Policy and other published policies, constitute the entire agreement between us and you pertaining to any and all access and use of the APP and supersede any and all prior or contemporaneous written or oral agreements between us and you pertaining thereto. No amendment or waiver by you of this TOS will be binding on us unless set forth in a writing expressly identifying this TOS and signed by you and us.</p>

        <p className="content">Any caption, heading or section title contained herein is inserted only as a matter of convenience, and in no way defines or explains any section or provision hereof.</p>

        <p className="content">We will collect and process your information and technical data in accordance with the Privacy Policy.</p>

        <p className="content">We administer and operate the APP from its location in Hong Kong. If you choose to access the APP from outside the Hong Kong, you do so on your own initiative and you are solely responsible for complying with applicable local laws.</p>

        <p className="heading">13. Contact Us</p>

        <p className="content">If you have any questions regarding the use of the APP or this TOS, please contact us at: support@ai-alchemist.live</p>
        </div>
    </div>
  )
}

export default TOS;
