// AccountManagement.js
import React, { useEffect, useState } from 'react';
import Header from './Header';
import { getAuth, signOut, deleteUser } from 'firebase/auth';
import { getDatabase, ref, remove } from 'firebase/database';
import { getFirestore, deleteDoc, doc, collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';


const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, message }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-backgroundColor p-8 rounded relative text-white">
          <button onClick={onClose} className="absolute top-2 right-2 text-lg">&#10005;</button>
          <h2 className="text-xl font-bold mb-4">{title}</h2>
          <p className="mb-4">{message}</p>
          <button onClick={onConfirm} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-4">Confirm</button>
          <button onClick={onClose} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cancel</button>
        </div>
      </div>
    );
  };

const AccountManagement = ({ setShowLogin }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const [isAccountDeletionModalOpen, setIsAccountDeletionModalOpen] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (!user) {
            setShowLogin(true); // Show login modal if not logged in
          }
        });
  
        return unsubscribe; // Cleanup subscription on component unmount
      }, [setShowLogin]);

    const handleDeleteAccountConfirm = () => {
        setIsAccountDeletionModalOpen(false);
        handleDeleteAccount();
      };      

    const handleDeleteAccount = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
      
        if (user) {
          try {
            // Delete user data from Firebase Realtime Database and Firestore
            await handleDeleteUserData();
      
            // Delete the user's account from Firebase Authentication
            await deleteUser(user);
      
            console.log('Account deleted successfully');
      
            // Log out and redirect to the homepage
            handleLogOut();
            // Redirect to homepage logic goes here, e.g., using navigate from react-router-dom
      
          } catch (error) {
            console.error('Error deleting account:', error);
            // Handle error (e.g., show error message)
          }
        }
      };

  const deletePublishedContent = async (userUid) => {
    const firestore = getFirestore();
    const querySnapshot = await getDocs(query(collection(firestore, 'communityShowcase'), where('uid', '==', userUid)));
    
    const batch = firestore.batch();
  
    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });
  
    await batch.commit();
  };  

  const handleDeleteUserData = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userUid = user.uid;
    
        // Deleting from Firebase Realtime Database
        const dbRealtime = getDatabase();
        const userContentRef = ref(dbRealtime, `users/${userUid}/creations`);
        await remove(userContentRef);
  
        // // // Deleting from Firestore (discussed in the next part)
        // await deletePublishedContent(userUid);
  
        console.log('User data deleted successfully');
        // Add additional logic if needed (e.g., updating UI)
      }
    } catch (error) {
      console.error('Error deleting user data:', error);
      // Handle error (e.g., show error message)
    }
  };

  const handleDeleteUserDataConfirm = () => {
    setIsModalOpen(false);
    handleDeleteUserData();
  };

  const handleLogOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      console.log('User logged out');
      // Redirect to login page or update UI accordingly
      setShowLogin(true); // If you want to show the login modal after logout
    } catch (error) {
      console.error('Error logging out:', error);
      // Handle error (e.g., show error message)
    }
  };
  

  return (
    <div className="lg:h-[900px] py-12" style={{ position: 'relative' }}>
      <Header setShowLogin={setShowLogin}/>
      <div className="container mx-auto p-4 mt-12">
        <h1 className="text-xl font-bold mb-24 lg:text-center text-center">Account Management</h1>
        <p className="text-center lg:text-center mb-16 text-white lg:w-1/2 lg:mx-auto">
          Here you can manage your account settings, including deleting your account or user data. 
          Please read the following information carefully before proceeding.
        </p>
        {/* User details and management options here */}
        
        <div className="flex lg:justify-center justify-center mb-4 mx-16">
          <button 
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => setIsModalOpen(true)}
          >
            Delete User Data
          </button>
        </div>
        <p className="text-sm lg:text-center mb-12 text-white lg:w-1/2 lg:mx-auto">
          <strong>Warning:</strong> Deleting your user data will remove all user-generated content and personal information from our systems. 
          However, settings and data saved on your device will remain, and you can continue to use our services with minimal disruption. 
          This action does not affect your subscription status or other account information.
        </p>
        

        <div className="flex lg:justify-center justify-center mb-4 mx-16">
          <button 
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => setIsAccountDeletionModalOpen(true)}
          >
            Delete Account
          </button>
        </div>
        <p className="text-sm lg:text-center mb-8 text-white lg:w-1/2 lg:mx-auto">
          <strong>Warning:</strong> Account deletion is a final and irreversible action. 
          This will permanently remove your account and all associated data from our systems. 
          Please be certain before proceeding.
        </p>
        <div className="flex lg:justify-center justify-center mb-4 mx-16">
        {/* ... other buttons ... */}
        <button 
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mx-2"
            onClick={handleLogOut}
        >
            Log Out
        </button>
        </div>
      </div>
      {/* Confirmation Modal */}
      <ConfirmationModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        onConfirm={handleDeleteUserDataConfirm}
        title="Confirm Deletion"
        message="Are you sure you want to delete all your user data? This action is irreversible."
      />
      <ConfirmationModal 
        isOpen={isAccountDeletionModalOpen} 
        onClose={() => setIsAccountDeletionModalOpen(false)} 
        onConfirm={handleDeleteAccountConfirm}
        title="Confirm Account Deletion"
        message="Are you sure you want to delete your account? This will permanently remove your account and all associated data. This action is irreversible."
    />

    </div>
  );
};

export default AccountManagement;
