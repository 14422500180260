// import icons
import { FaYoutube, FaInstagram, FaGithub, FaGooglePlay, FaTwitter } from 'react-icons/fa';
import { BsChatDotsFill } from 'react-icons/bs';

// import images
import AboutImg from '../src/assets/img/about.png';
import Feature1Img from '../src/assets/img/features/feature1.png';
import Feature2Img from '../src/assets/img/features/feature2.png';
import Feature3Img from '../src/assets/img/features/feature3.png';
import Feature4Img from '../src/assets/img/features/feature4.png';
import Avatar1Img from '../src/assets/img/testimonials/avatar1.png';
import Avatar2Img from '../src/assets/img/testimonials/avatar2.png';
import Avatar3Img from '../src/assets/img/testimonials/avatar3.png';
import AvatarAI1 from '../src/assets/img/aiimages/alchemy.jpg';
import AvatarAI2 from '../src/assets/img/aiimages/anime2.png';
import AvatarAI3 from '../src/assets/img/aiimages/mascot.png';
import AvatarAI4 from '../src/assets/img/aiimages/nigredo.png';
import Icon from '../src/assets/img/Icon.png';
import LogoV2 from '../src/assets/img/logo-v2.png';
import HeroImage from '../src/assets/img/hero-img.png';
import Feature1BgImg from '../src/assets/img/features/feature1_bg.png';
import Feature2BgImg from '../src/assets/img/features/feature2_bg.png';
import Feature3BgImg from '../src/assets/img/features/feature3_bg.png';
import Feature4BgImg from '../src/assets/img/features/feature4_bg.png';

export const navigationData = [
  // {
  //   name: 'Create',
  //   href: '#',
  // },
  // {
  //   name: 'Edit',
  //   href: '#',
  // },
  // {
  //   name: 'News',
  //   href: '#',
  // },
  {
    name: 'Policy',
    href: '/privacy-policy',
  },
  {
    name: 'Terms of Service',
    href: '/terms-of-service',
  },
  // {
  //   name: 'Login',
  //   href: '#',
  // },
  {
    name: 'Account',
    href: '#',
  },
];

export const heroData = {
  title: ` Craft your Alchemy`,
  subtitle:
    "Welcome to our world of possibilities. Discover a realm where imagination meets technology, and innovation becomes reality. Embark on a journey of exploration.",
  btnText: `Get Started`,
  icon: <FaGooglePlay/>,
  image: "https://storage.googleapis.com/ai-alchemist-models/website/banner_40_mk2.jpg",
  video:'https://storage.googleapis.com/ai-alchemist-models/Videos/Landscape%20Ad%201.mp4'
};

export const aboutData = [{
  image: 'https://storage.googleapis.com/ai-alchemist-models/website/00417-2769999098.png',
  title: 'APP Into video',
  subtitle:
    `1girl, bare arms, bare shoulders, cutoffs, daisy, day, denim shorts,`
},
{
  image: 'https://storage.googleapis.com/ai-alchemist-models/website/00523-2493450695.png',
  title: 'APP Into video',
  subtitle:
    `(shibuya:1.5),kawaii girl,high neck ribbed crop knit top,walking`
},
{
  image: 'https://storage.googleapis.com/ai-alchemist-models/website/01210-3631886670.png',
  title: 'APP Into video',
  subtitle:
    `heels, sitting down,mini dress, flower on hair, brown hair`
},
{
  image: 'https://storage.googleapis.com/ai-alchemist-models/website/00106-887067131.png',
  title: 'APP Into video',
  subtitle:
    '1girl, kpop idol, (Luxury Hotel:1.2), detailed face, close up, beautiful eyes, pretty face, (bright skin:1.3), idol, dress'
},
{
  image: 'https://storage.googleapis.com/ai-alchemist-models/website/00729-2285019074.png',
  title: 'APP Into video',
  subtitle:
    'Shiny armor incrusted with several diamonds with cross designs, red black war_glam corset ,black frills and ribbons, cross, cross earring, cross necklace, cross ornaments'
},];

export const featuresData = {
  title: 'Some Services We Offer',
  subtitle:
    'With our app you can view the route of your order, from our local headquarters to the place where you are. Look for the app now!',
  list: [
    {
      image: AvatarAI1,
      bgImage: Feature1BgImg,
      title: 'Cutting Edge of AI Technology',
      description:
        'Pay with a Visa or PayPal card and without much ado',
      linkText: 'Learn more',
      delay: '400',
    },
    {
      image: AvatarAI4,
      bgImage: Feature2BgImg,
      title: 'Find Your Product',
      description:
        'We offer sale of products through the Internet..',
      linkText: 'Learn more',
      delay: '700',
    },
    {
      image: AvatarAI3,
      bgImage: Feature3BgImg,
      title: 'Print Out',
      description:
        'Print out service gives you convenience if someday you need print data, just edit it all and just print it.',
      linkText: 'Learn more',
      delay: '1000',
    },
    {
      image: AvatarAI2,
      bgImage: Feature4BgImg,
      title: 'Product Received',
      description:
        'In our app you can see the delay time of your order...',
      linkText: 'Learn more',
      delay: '1300',
    },
  ],
};

export const testimonialsData = [
  {
    image: Icon,
    name: 'Christopher Tillack',
    // web: 'rena.com',
    message:
      "Great AI art app. It's quick and accurate. Follows the prompts perfectly. There's a fee of one ad per generation. Perfect.",
    delay: '300',
  },
  {
    image: Icon,
    name: 'Natalia',
    // web: 'nataliya.com',
    message:
      'Prompt',
    delay: '600',
  },
  {
    image: Icon,
    name: 'Vebin',
    // web: 'vebin.com',
    message:
      'Prompt',
    delay: '900',
  },
];

export const ctaData = {
  title: 'Ai Alchemist',
  subtitle: 'Download now and unlock your passion!',
  // btnText1: 'Learn more',
  btnText2: 'Download now',
};

export const footerData = {
  logo: Icon,
  address: 'Tokyo, Japan',
  email: 'support@ai-alchemist.live',
  phone: '1-232-7788 (Main)',
  list1: [
    // {
    //   name: 'Profile',
    //   href: '#',
    // },
    {
      name: 'Features',
      href: '#',
    },
    // {
    //   name: 'Careers',
    //   href: '#',
    // },
    // {
    //   name: 'DW News',
    //   href: '#',
    // },
  ],
  list2: [
    {
      name: 'Terms of Service',
      href: '/terms-of-service',
    },
    // {
    //   name: 'Sign Up',
    //   href: '#',
    // },
    // {
    //   name: 'Guide',
    //   href: '#',
    // },
    // {
    //   name: 'Reports',
    //   href: '#',
    // },
    {
      name: 'Privacy Policy',
      href: '/privacy-policy',
    },
  ],
  socialList: [
    {
      icon: <FaTwitter />,
      href: 'https://twitter.com/AiAlchemistLive',
    },
    {
      icon: <FaInstagram />,
      href: 'https://www.instagram.com/ai.alchemist.live/',
    },
    // {
    //   icon: <FaGithub />,
    //   href: '#',
    // },
  ],
};

export const copyrightData = {
  text: '© Product AI - Alchemist Tokyo, 2023. All rights reserved.',
  // icon: <BsChatDotsFill />,
};

export const reviewData = {
  reviewList: [
    {text: "Raju Koltur: I have tried several AI art apps, but this one stands out for its diverse selection of styles and variations and the ability to upscale the image to 1028 is fascinating. It's easy to use and produces outstanding results every time."},
    {text: "Christopher Tillack: Great AI art app. It's quick and accurate. Follows the prompts perfectly. There's a fee of one ad per generation. Perfect."},
    {text: "Nathan B.: Best AI image generator I've tried so far. I hope to get a subscription next month."},
    {text: "Tommy “Pockets” Sheridan: Amazing graphics and the moe detailed your input into it the more on spot it gets. Great work"},
    {text: "Mycheila Rose: I love all of what this application can do for me."},
    {text: "Thomas Ogletree: Wonderfully awesome renderings"},
    {text: "munnin raveneye: Love it and it's NSFW js"},
    {text: "Owen Zew: Pretty cool 😎"},
    {text: "Saraswati Koltur: I'm amazed by the level of detail this AI art app can achieve. The different models and styles are incredible, and the images are generated fast. Highly recommend for AI art enthusiasts!"},
    {text: " Sunny YangX: Five stars for it. AI Alchemist is a game-changer for digital artists and hobbyists alike. With its intuitive interface and a vast library of AI models, it turns every moment into a potential masterpiece. The Timeless Recall feature adds a unique touch, allowing for a fusion of past, present, and future in my creations. Regular updates keep the app fresh and exciting. It's not just an app; it's a growing community for creators who appreciate the magic of digital alchemy. Highly recommended for anyone looking to explore new artistic horizons. A solid five stars for AI Alchemist!"}
  ] 
};
