import { useState, useEffect, useCallback } from "react";
import { storage } from "./firebase";
import { getFirestore, collection, query, getDocs, orderBy, limit } from 'firebase/firestore';

function Gallery() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchProjectData = useCallback(async () => {
    setIsLoading(true);
    try {
      const db = getFirestore();
      const q = query(collection(db, "communityShowcase"), orderBy("likesCount", "desc"), limit(15));
      const querySnapshot = await getDocs(q);
      const newData = querySnapshot.docs.map(doc => {
        return { ...doc.data(), id: doc.id };
      });
      setData(newData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProjectData();
  }, [fetchProjectData]);

  return (
    <section className='my-[70px] xl:my-[150px]'>
      <div className="App min-h-screen p-6">
        <div className='mx-auto max-w-2xl py-16 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8'>
        <h2 className="h2 font-bold text-center mb-6 xl:mb-12 text-4xl xl:text-5xl" data-aos='fade-down' data-aos-delay='100'>Community Showcase</h2>
        <p className="p lead max-w-[584px] text-xl mx-auto mb-16 xl:mb-24" data-aos='fade-down' data-aos-delay='200'>
              Step into the enchanted gallery where the alchemy of imagination meets the magic of AI. Each masterpiece in our Community Showcase has been conjured up by the brilliant minds of AI Alchemist’s adepts, gaining favor through a potion of likes!
            </p>
          <div className='columns-2 md:columns-3 lg:columns-3'>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              data.map((item) => (
                <div
                  key={item.id}
                  className="relative group"
                >
                  <div className="relative mb-4 before:content-[''] before:rounded-md before:absolute before:inset-0 before:bg-black before:bg-opacity-20">
                    <img
                      src={item.projectUri}
                      alt=""
                      className="h-auto max-w-full rounded-lg"
                    />
                    <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 "></div>
                  </div>
                  <div className="absolute inset-0 flex opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <p className="text-white px-4 py-4 rounded-lg">
                      by @{item.username}
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Gallery;
