import React, { useEffect, useState } from 'react';
// import { Home, TOS } from './components/TermsService';

// import animate on scroll
import Aos from 'aos';
import 'aos/dist/aos.css';

// import router components
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import components
import Hero from './components/Hero';
import About from './components/About';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import CtaSection from './components/CtaSection';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy'; // Make sure this path is correct
import Gallary from './components/Gallary';
import { heroData } from './data';
import TOS from './components/TermsService'; // Make sure this path is correct
import Login from './components/Login';
import AccountManagement from './components/AccountManagement';

const Home = ({ setShowLogin }) => {
  // animate on scroll initialization
  useEffect(() => {
    Aos.init({
      duration: 1800,
      offset: 0,
    });
  }, []);
  const [currentMediaURL, setCurrentMediaURL] = useState(heroData.image);
  
  return (
    <div className='overflow-hidden'>
      <Hero mediaURL={currentMediaURL} setShowLogin={setShowLogin} />
      <About setMediaURL={setCurrentMediaURL} />
      {/* <Features />
      <Testimonials /> */}
      <Gallary />
      <CtaSection />
      <Footer />
    </div>
  );
};

const DataManagement = ({ setShowLogin }) => {
  // animate on scroll initialization
  useEffect(() => {
    Aos.init({
      duration: 1800,
      offset: 0,
    });
  }, []);
  
  return (
    <div className='overflow-hidden'>
      <AccountManagement setShowLogin={setShowLogin}/>
      <Footer />
    </div>
  );
};

const App = () => {
  const [showLogin, setShowLogin] = useState(false);

  const handleLoginClose = () => {
    setShowLogin(false);
  };
  return (
    <Router>
      <Routes>
        <Route path="/terms-of-service" element={<TOS />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/account-management" element={<DataManagement setShowLogin={setShowLogin} />} />
        <Route path="/*" element={<Home setShowLogin={setShowLogin} />} />
         {/* <Route path="/gallary" element={<Gallary />} />*/}
      </Routes>
      {showLogin && <Login onClose={handleLoginClose} setShowLogin={setShowLogin} />}
    </Router>
  );
};

export default App;
