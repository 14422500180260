import React from 'react';

// import navigation data
import { navigationData } from '../data';
import GoogleIcon from '../assets/img/google-play-badge-200h.png';
import iOSIcon from '../assets/img/app-store-badge-200h.png';

const NavMobile = ({ setShowLogin, setNavMobile }) => {

  const handleAccountClick = () => {
    setShowLogin(true);
    setNavMobile(false);
  };


  return (
    <ul className='flex flex-col px-6 py-8 gap-y-4'>
      {navigationData.map((item, index) => {
        return (
          <li key={index}>
            <a className='text-white' href={item.href} onClick={item.name === 'Account' ? handleAccountClick : null}>
              {item.name}
            </a>
          </li>
        );
      })}
      <li className='flex space-x-4'>
          <a href="https://apps.apple.com/us/app/ai-alchemist/id6467228918" target="_blank" rel="noopener noreferrer">
            <img src={iOSIcon} alt="Download on the App Store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.ai.alchemist" target="_blank" rel="noopener noreferrer">
            <img src={GoogleIcon} alt="Get it on Google Play" />
          </a>
      </li>
      <div className={`flex space-x-4 hidden lg:flex`}>
            <a href="https://apps.apple.com/us/app/ai-alchemist/id6467228918" target="_blank" rel="noopener noreferrer">
              <img src={iOSIcon} alt="Download on the App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.ai.alchemist" target="_blank" rel="noopener noreferrer">
              <img src={GoogleIcon} alt="Get it on Google Play" />
            </a>
          </div>
    </ul>
  );
};

export default NavMobile;
