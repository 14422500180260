import './TermsService.css'; // You'll have to create this stylesheet
import styles from './TermsService.module.css';
import React, { useEffect, useState } from 'react';

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}> {/* Use styles.container */}
      <h1 className="title">AI Alchemist: Privacy Policy</h1>
      <div className="contentContainer">
        <p className="dateOfRevision">
          Date of Revision: [ 07/2023 ]
        </p>
        <p className="heading">Introduction</p>

        <p className="content">
        {'\n'}
        Welcome to AI Alchemist (the “APP”). We are committed to safeguarding your privacy and take it very seriously. Your data will be collected, processed, and used for the sole purpose of providing services in the APP and will not be disclosed to any parties. We will ask for your consent to collect any necessary information.{'\n'}
        {'\n'}
        If you have any queries or need clarification on our Privacy Policy, please feel free to Contact Us or as described at the end of this Policy. It's important to remember that using our APP also implies agreeing to our Terms of Service.
        </p>
        <p className="heading">Third Party Sites</p>
        <p className="content">
        This Privacy Policy applies only to information we collect at and through the APP. Our APP may also contain links to third party sites that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other third party sites. We encourage you to be aware when you leave our APP and to read the privacy statements of each and every website that collects personal information.
        </p>
        <p className="heading">Information We Collect</p>
        <p className="content">
        1.1 The information we collect falls into one of two categories: "voluntarily provided" and "automatically collected" information. "Voluntarily provided" refers to any information you knowingly provide us when using our APP, and we will ask for your consent when we need to collect your information. "Automatically collected" information refers to data sent by your device when accessing our APP. We only collect this information to the minimum extent necessary to provide our services.
        </p>
        <p className="content">
        1.2 Functions and related information:
        </p>
        <li className="bulletItem">
        We only collect the images you generate in our APP for the purpose of providing our service. Your images will be stored in our server for a temporary period and will be automatically deleted after 7 days. This storage is essential for providing the image generation service, and you can choose to delete your images at any time.
        </li>
        <p className="content">
        <br/>
        1.3 Types of Information we collect:
        </p>
        <li className="bulletItem">
        Log Data: We may automatically log the standard data provided by your device. It may include your device's Internet Protocol (IP) address, your device type and version, your activity within the APP, time and date, and other details about your usage. This data does not personally identify you.
        </li>
        <li className="bulletItem">
        Personal Information: We do NOT ask for personal identification information such as names, email addresses, or any form of contact information. We also do NOT require you to log in using third-party profiles such as Apple or Google.
        </li>
        <li className="bulletItem">
        User Account: A user account is not required to use our APP.
        </li>
        <li className="bulletItem">
        User Generated Content: We consider "user-generated content" to be the images voluntarily generated by our users using our APP. These images are temporarily stored and will be deleted after 7 days. We do not share your generated images with any third party.
        </li>
        <li className="bulletItem">
        Payment Data: Purchase transactions are done through the Google Play Store and Apple App Store, you shall carefully read their payment terms, terms of services, private policies and any other related terms. We do NOT collect your payment information directly.
        </li>
        <li className="bulletItem">
        Device Information: We may collect information about your mobile device and internet connection, including the device's unique device identifier, operating system, browser type, and mobile network information. This information is collected to ensure compatibility and to provide a better service.
        </li>

        <p className="heading">2. How We Collect Information</p>
        <p className="content">
        We use the information we collect for the following primary purposes:
        </p>

        <p className="heading">3. How We Use Your Information</p>
        <p className="content">
        We use the information we collect for the following primary purposes:
        </p>
        <li className="bulletItem">
        To provide you with the services available through Ai Alchemist, such as generating images.
        </li>
        <li className="bulletItem">
        To fulfill any other purpose for which you provided the information. For example, if you provide us with your email, we might use it to send you updates and news about Ai Alchemist.
        </li>
        <li className="bulletItem">
        To carry out our obligations and enforce our rights arising from any contracts entered between you and us.
        </li>
        <li className="bulletItem">
        To notify you about updates to Ai Alchemist and any products or services we offer.
        </li>
        <li className="bulletItem">
        To improve and optimize Ai Alchemist by learning about user habits, preferences, and other usage patterns.
        </li>
        <p className="content">
        Please be aware that no system or network can guarantee absolute security, even with the stringent measures we have put in place to protect your data. We will strive to protect your data and notify you of any potential security risks.
        </p>

        <p className="heading">4. No Third-Party Information Collection</p>
        <p className="content">
        We do not share your data with any third parties, and we do not use third-party services for data collection. Your data is exclusively used to provide and improve the services offered by Ai Alchemist.
        </p>
        <p className="heading">5. Data Retention and Transfer</p>
        <p className="content">
        We store your generated images, referred to as “Creations”, on our servers for a duration of 7 days. After this period, these Creations are automatically deleted. Your account-related data, including avatar and nickname, will be retained until you voluntarily delete them. We aim to retain your personal data only for as long as necessary, in compliance with applicable data privacy laws.
        </p>
        <p className="content">
        Ai Alchemist is maintained in the Hong Kong Special Administrative Region (“HKSAR”). The data you provide may be stored, processed, and accessed both in HKSAR and other districts or countries. By using our services, you consent to the storage and processing of your information in these areas.
        </p>
        <p className="content">
        For users from the EU, your data transfer to these countries is protected by standard contractual clauses adopted by the European Commission and compliant with EU data protection laws.
        </p>

        <p className="heading">6. Disclosure of Your Information</p>
        <p className="content">
        We do not disclose your personal information to any third parties, subsidiaries, affiliates, or service providers. Your personal information will not be used for any purpose not stated in this privacy policy. We strictly adhere to our policy of not sharing user data.
        </p>
        <p className="content">
        In case of a legal requirement, such as a court order or government request, or in the event of a merger or acquisition, we may be obliged to disclose your information. However, in such scenarios, we will take all possible steps to ensure your data privacy.        </p>
        
        <p className="heading">7. How You Can Manage or Delete Your Information</p>
        <p className="content">
        You hold various rights regarding your personal data including access, data portability, and rectification. This allows you to request a copy of your data in a commonly used, machine-readable format, and to request any corrections to inaccurate data. You may also complete your personal data if necessary.
        </p>
        <p className="content">
        You may withdraw your consent for us to collect and process your data at any time by contacting us at: support@ai-alchemist.live. Following your withdrawal, we will cease to process your personal data, unless required by law.
        </p>
        <p className="content">
        Sharing your Creations via the app on social media and other websites is at your own risk. We cannot control or take responsibility for the actions of third parties with whom you choose to share your Creations.
        </p>
        <p className="content">
        To manage your information: 
        </p>
        <li className="bulletItem">
        You can delete specific data using available tools. For instance, the 'Delete' button allows you to remove your 'Creations'. Be aware of the consequences before you delete or remove content.
        </li>
        <li className="bulletItem">
        To permanently delete your account, use the 'Delete Account' button. This will erase all your app data, including all Creations and purchase records. Remember, this action is irreversible. If you registered with an email address, that email can't be used to log in once your account is deleted.
        </li>

        <p className="heading">8. European Privacy Rights</p>
        <p className="content">
        If you are a resident of the European Union, you have certain additional rights regarding your personal data under the General Data Protection Regulation (Regulation (EU) 2016/679) (the “GDPR”). To exercise these rights, contact us at support@ai-alchemist.live. We may need specific information from you to confirm your identity. In some cases, a reasonable fee may be charged for access to your information.
        </p>
        <p className="content">
        Should you believe that our data processing infringes upon your GDPR rights, and if your concerns are not satisfactorily addressed by us, you have the right to lodge a complaint with your country's data protection supervisory authority.
        </p>

        <p className="heading">9. Children's Policy</p>
        <p className="content">
        Our application is not intended for individuals under 18 years of age, or under the Minimum User Age as per local regulations. We do not knowingly collect personal data from children below this age. If we discover such data has been collected without parental consent, we will delete it. If you are aware of any data we might have collected from a child under the Minimum User Age, please contact us at support@ai-alchemist.live.
        </p>
        <p className="content">
        If you're a resident of the European Union, due to the EU General Data Protection Regulation, you must be at least 18 years old to use our services unless permission has been given by a parent or guardian.
        </p>

        <p className="heading">10. Changes to Our Privacy Policy</p>
        <p className="content">
        We may update our privacy policy periodically to continue providing our services effectively and to comply with the relevant laws. We'll notify you of any changes, so please check this privacy policy page periodically. If you disagree with any changes, you should cease using the app immediately. Continued use of the services after a reasonable time from the update notification will be considered as acceptance of all changes, unless there's a legal obligation on our part to obtain your explicit consent for the changes.
        </p>

        <p className="heading">11. How to Contact Us</p>
        <p className="content">
        For any questions or comments about this privacy policy or our privacy practices, please contact us at: support@ai-alchemist.live.
        </p>
        </div>
    </div>
  )
}

export default PrivacyPolicy;
